.upload-file-list {
  margin-top: 24px!important;
  padding-top: 0!important;
  max-width: 550px!important;
}

.file-upload-item {
  padding-left: 24px!important;
  padding-top: 24px!important;
}

.file-upload-item-row {
  padding-top: 24px!important;
}

.file-upload-item-column {
  padding: 8px 16px!important;
}

@media (min-width: 1536px) {
  .upload-file-list {
    padding-top: var(--spacing-scale-2x)!important;
  }
}

@media (max-width: 576px) {
  .file-upload-item-column {
    padding: 2px 4px!important;
  }

  .file-upload-item {
    max-width: 90%;
    width: auto!important;
    padding-left: 6px!important;
    padding-top: 6px!important;
  }

  .file-upload-item-row {
    padding-top: 6px!important;
  }
}