:root {
  --icon-size-base: 1em;
  --icon-size-xs: .5em;
  --icon-size-sm: .75em;
  --icon-size-lg: 1.25em;
  --icon-size-2x: 2em;
  --icon-size-3x: 3em;
  --icon-size-4x: 4em;
  --icon-size-5x: 5em;
  --icon-size-6x: 6em;
  --icon-size-7x: 7em;
  --icon-size-8x: 8em;
  --icon-size-9x: 9em;
  --icon-size-10x: 10em;
}