.fontFamilyInnerHTML {
  font-family: "Roboto","Helvetica","Arial",sans-serif!important;
  font-size: 14px;
  letter-spacing: -0.05px;
  line-height: 21px;
}

/* Small devices such as large phones (40em and up) */
@media only screen and (min-width: 640px) {
  .cardPrincipal {
    padding: 6% 6% 15% 6%;
  }
}

/* Medium devices such as tablets (48em and up) */
@media only screen and (min-width: 768px) {
  .cardPrincipal {
    padding: 6% 6% 15% 6%;
  }
}

/* Large devices such as laptops (64em and up) */
@media only screen and (min-width: 1024px) {
  .cardPrincipal {
    padding: 6%;
  }
}

/* Largest devices such as desktops (80em and up) */
@media only screen and (min-width: 1280px) {
  .cardPrincipal {
    padding: 6%;
  }
}