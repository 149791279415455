:root {
  --blue-warm-vivid-90: #071D41;
  --blue-warm-vivid-80: #0C326F;
  --blue-warm-vivid-70: #1351B4;
  --blue-warm-vivid-60: #155BCB;
  --blue-warm-vivid-50: #2670E8;
  --blue-warm-vivid-40: #5992ED;
  --blue-warm-vivid-30: #81AEFC;
  --blue-warm-vivid-20: #ADCDFF;
  --blue-warm-vivid-10: #D4E5FF;
  --blue-warm-vivid-5: #EDF5FF;
  --blue-warm-vivid-70-rgb: 19, 81, 180;
}
