:root {
  --blue-warm-90: #13171F;
  --blue-warm-80: #252F3E;
  --blue-warm-70: #2F4668;
  --blue-warm-60: #345D96;
  --blue-warm-50: #4A77B4;
  --blue-warm-40: #7292C7;
  --blue-warm-30: #98AFD2;
  --blue-warm-20: #C5D4EB;
  --blue-warm-10: #E1E7F1;
  --blue-warm-5: #ECF1F7;
}