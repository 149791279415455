/* Easing */
:root {
  --animation-ease-in: ease-in;
  --animation-ease-out: ease-out;
  --animation-ease-in-out: ease-in-out;
  --animation-ease: ease;
}

/* Linear */
:root {
  --animation-ease-linear: linear;
}

/* Cubic-Bezier 
Easing      |	CSS                               |	Easing Token
Ease	      | cubic-bezier (0.25, 0.1, 0.25, 1) |	--animation-ease
Ease-In     |	cubic-bezier 0.42, 0, 1, 1)	      | --animation-ease-in
Ease-Out    |	cubic-bezier (0, 0, 0.58, 1)      |	--animation-ease-out
Ease-In-Out |	cubic-bezier (0.42, 0, 0.58, 1)   |	--animation-ease-in-out
Linear	    | cubic-bezier (0,0,1,1)            |	--animation-ease-linear
*/

/* Duração
Tempo       | (s)	Tipo	   |Área
0,10 - 0,29	| Muito rápido | Micro animação
0,30 - 0,49	| Rápido	     | Micro animação
0,50 - 0,79	| Moderado     | Média animação
0,80 - 0,99	| Lento	       | Macro animação
1 - *	Muito | lento	       | Macro animação

Animações de feedback simples, como mostrar checkbox ou um switch, deve ter aproximadamente 0,10 segundos de duração total.

Quando uma janela modal é exibida, um movimento do tipo rápido pode ser apropriado. Quanto mais um elemento precisa se mover, mais importante é que ele o faça de maneira suave e não chocante. (0,10 - 0,29)

pop-up: aparecer => 0,30; desaparecer => 0,20
*/

/* {Text} */
:root {
  --duration-very-fast: 0.10s;
  --duration-fast: 0.30s;
  --duration-moderate: 0.50s;
  --duration-slow: 0.80s;
  --duration-very-slow: 1s;
}