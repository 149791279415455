:root {
  --red-warm-vivid-80: #3E2A1E;
  --red-warm-vivid-70: #63340F;
  --red-warm-vivid-60: #9C3D10;
  --red-warm-vivid-50: #D54309;
  --red-warm-vivid-40: #EF5E25;
  --red-warm-vivid-30: #F39268;
  --red-warm-vivid-20: #F6BD9C;
  --red-warm-vivid-10: #FCE1D4;
  --red-warm-vivid-5: #FFF5EE;
}