.app-form-footer {
  background-color: #F1F1F1;
  width: 100%;
  border-bottom: 6px solid #3F51B4;
}

.app-form-footer-content {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  width: 95%;
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}