.app-footer {
  background-color: #F1F1F1;
  width: 100%;
  border-bottom: 6px solid #1351b4;
}

.app-footer-content {
  width: 95%;
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 0;
}

.app-footer-info {
  position: initial;
  margin-left: 20px;
  margin-bottom: 10px;
}

/* Small devices such as large phones (40em and up) */
@media only screen and (min-width: 640px) {
  .app-footer-info {
    position: initial;
    margin-left: 20px;
    margin-bottom: 10px;
  }
}

/* Medium devices such as tablets (48em and up) */
@media only screen and (min-width: 768px) {
  .app-footer-info {
    position: initial;
    margin-left: 20px;
    margin-bottom: 10px;
  }
}

/* Large devices such as laptops (64em and up) */
@media only screen and (min-width: 1024px) {
  .app-footer-info {
    position: fixed;
    bottom: 20px;
    left: 50px;
    margin-bottom: 10px;
  }
}

/* Largest devices such as desktops (80em and up) */
@media only screen and (min-width: 1280px) {
  .app-footer-info {
    position: fixed;
    bottom: 20px;
    left: 50px;
    margin-bottom: 10px;
  }
}