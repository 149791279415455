.app-form-header {
  background-color: #3F51B4;
  box-sizing: content-box;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.app-form-header-content {
  width: 95%;
  max-width: 1150px;
  margin: 0 auto;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}