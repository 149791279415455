/* Align */
:root {
  --spacing-vertical-top: top;
  --spacing-vertical-center: center;
  --spacing-vertical-bottom: bottom;
  --spacing-horizontal-left: left;
  --spacing-horizontal-center: center;
  --spacing-horizontal-right: right;
}

/* Scale */
:root {
  --spacing-scale-default: 0em;
  --spacing-scale-base: 1em;
  --spacing-scale-2x: 2em;
  --spacing-scale-3x: 3em;
  --spacing-scale-4x: 4em;
  --spacing-scale-5x: 5em;
  --spacing-scale-6x: 6em;
  --spacing-scale-7x: 7em;
  --spacing-scale-8x: 8em;
  --spacing-scale-9x: 9em;
  --spacing-scale-10x: 10em;
  --spacing-scale-half: 0.5em;
  --spacing-scale-baseh: 1.5em;
  --spacing-scale-2xh: 2.5em;
  --spacing-scale-3xh: 3.5em;
  --spacing-scale-4xh: 4.5em;
  --spacing-scale-5xh: 5.5em;
}