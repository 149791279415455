.fontFamilyInnerHTML {
  font-family: "Roboto","Helvetica","Arial",sans-serif!important;
  font-size: 14px;
  letter-spacing: -0.05px;
  line-height: 21px;
}

div.quill div.ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

div.quill div.ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}