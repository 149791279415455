@import url('../../App.css');

.amplify-alert--warning {
  color: #A05D1A!important;
  background-color: #F5D9BC!important;
}

@media (prefers-color-scheme: dark) {
  [data-amplify-color-mode="system"] {
    --amplify-colors-background-primary: rgba(255, 255, 255, 0.08)!important;
  }
}

[data-amplify-color-mode="dark"] {
  --amplify-colors-background-primary: rgba(255, 255, 255, 0.08)!important;
}

[data-amplify-color-mode="dark"] .amplify-button {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  color: var(--interactive-dark)!important;
}

[data-amplify-color-mode="dark"] .amplify-button--primary {
  --interactive-rgb: var(--background-dark-rgb);
  background-color: var(--interactive-dark)!important;
  color: var(--background-dark)!important;
}

[data-amplify-color-mode="dark"] .amplify-button--primary:hover {
  background-color: var(--interactive-dark)!important;
}

.amplify-button {
  --button-radius: 100em;
  --button-xsmall: 24px;
  --button-small: 32px;
  --button-medium: 40px;
  --button-large: 48px;
  --button-size: var(--button-medium);
  align-items: center!important;
  background-color: transparent!important;
  border: 0!important;
  border-radius: var(--button-radius)!important;
  color: var(--interactive)!important;
  cursor: pointer!important;
  display: inline-flex!important;
  font-size: 16.8px!important;
  font-weight: var(--font-weight-semi-bold)!important;
  height: var(--button-size)!important;
  justify-content: center!important;
  overflow: hidden!important;
  padding: 0 24px!important;
  position: relative!important;
  text-align: center!important;
  vertical-align: middle!important;
  white-space: nowrap!important;
  width: auto!important;
  text-transform: capitalize!important;
}

.amplify-button:hover {
  background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))!important;
}

.amplify-button--primary {
  --interactive-rgb: var(--color-dark-rgb)!important;
  background-color: var(--interactive-light)!important;
  color: var(--color-dark)!important;
}

.amplify-button--primary:hover {
  background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))!important;
}